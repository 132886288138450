<template>
    <div>
        <invoicable-view-template
            :title="$t($route.meta.title)"
            :current-invoicable="currentInvoicable"
            :invoicable-type="invoicableType"
            :loading="loading"
        >
            <template #actions>
                <v-card class="pa-5">
                    <v-card-header>
                        <template #title> {{ $t('general.actions') }} </template>
                    </v-card-header>
                    <v-col cols="12" md="12" class="pa-0">
                        <submit-button
                            v-if="showCreateNewButton"
                            id="createNew"
                            data-action="createNew"
                            color="primary"
                            block
                            class="my-3"
                            @click="createNewInvoicable($route.params.type, currentInvoicable.historical)"
                        >
                            {{ createNewText }}
                        </submit-button>
                        <submit-button
                            v-if="currentInvoicable.draft === false"
                            id="sendeMail"
                            data-action="send-email"
                            color="primary"
                            block
                            class="my-3"
                            @click="sendEmailInvoicable($route.params.type, $route.params.id)"
                        >
                            {{ $t('viewInvoicable.send_email') }}
                        </submit-button>
                        <submit-button
                            id="edit"
                            data-action="edit"
                            color="primary"
                            block
                            class="my-3"
                            @click="
                                navigateToEditInvoicable(
                                    $route.params.id,
                                    $route.params.type,
                                    currentInvoicable.historical
                                )
                            "
                        >
                            {{ $t('general.edit') }}
                        </submit-button>
                        <submit-button
                            id="edit"
                            data-action="copy-publicurl-to-clipboard"
                            color="primary"
                            block
                            class="my-3"
                            @click="copyToClipboard"
                        >
                            <v-icon class="mr-1">mdi-clipboard-multiple-outline</v-icon>
                            {{ $t('general.copy_url') }}
                        </submit-button>
                        <open-messenger-modal-button
                            :polymorphic-document-id="currentInvoicable.id"
                            :document-id="documentId"
                        />
                    </v-col>
                </v-card>
            </template>
        </invoicable-view-template>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import SubmitButton from '@/components/SubmitButton.vue';
import VCardHeader from '@/components/vCardHeader.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { toClipboard } from '@/helpers/general';
import { getAccountingInvoicablelById } from '@/services/invoicable';
import InvoicableViewTemplate from './components/InvoicableViewTemplate.vue';
import OpenMessengerModalButton from '@/views/communication/OpenMessengerModalButton';

export default {
    components: {
        SubmitButton,
        VCardHeader,
        InvoicableViewTemplate,
        OpenMessengerModalButton
    },

    mixins: [formRulesMixin, screenSizeMixin],

    beforeRouteEnter(to, from, next) {
        const params = to.params;
        if (!(params.id && params.type)) {
            return next({
                name: 'dashboard'
            });
        }

        if (!['invoice', 'creditnote', 'quotation'].includes(params.type)) {
            return next({
                name: 'dashboard'
            });
        }

        return next();
    },

    data() {
        return {
            currentInvoicable: null,
            loading: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        showCreateNewButton() {
            return this.$route.query.newlyCreated;
        },
        invoicableType() {
            return this.$route.params.type;
        },
        createNewText() {
            if (this.$route.params.type === 'invoice') {
                return this.$t('viewInvoicable.creat_new_invoice');
            } else if (this.$route.params.type === 'quotation') {
                return this.$t('viewInvoicable.creat_new_quotation');
            } else if (this.$route.params.type === 'creditnote') {
                return this.$t('viewInvoicable.creat_new_creditnote');
            }

            throw new Error('Invalid type');
        },
        documentId() {
            return this.currentInvoicable.documentId;
        }
    },

    async created() {
        this.loading = true;
        try {
            this.isCreatingNew = false;
            [this.currentInvoicable] = await Promise.all([
                getAccountingInvoicablelById({
                    id: this.$route.params.id,
                    type: this.$route.params.type
                })
            ]);

            if (this.currentInvoicable.historical && !this.currentInvoicable.url) {
                return this.$router.replace({
                    name: `edit-accounting-historical-${this.$route.params.type}`,
                    params: {
                        id: this.$route.params.id
                    }
                });
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.loading = false;
        }
    },

    methods: {
        copyToClipboard() {
            toClipboard.call(this, this.generatePublicUrl(this.currentInvoicable.uuid));
        },
        generatePublicUrl(uuid) {
            return `${window.location.host}/public/invoice/${uuid}`;
        },
        navigateToEditInvoicable(id, type, historical) {
            this.$router.push({
                name: historical ? `edit-accounting-historical-${type}` : `edit-accounting-${type}`,
                params: {
                    id
                }
            });
        },
        createNewInvoicable(type, historical) {
            this.$router.push({
                name: historical ? `add-accounting-historical-${type}` : `add-accounting-${type}`
            });
        },
        sendEmailInvoicable(type, id) {
            this.$router.push({
                name: `send-${type}-email`,
                params: {
                    id
                }
            });
        }
    }
};
</script>
